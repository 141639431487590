const baseUrl = () =>
  process.env.NODE_ENV === 'production'
    ? `${process.env.BASE_URL}`
    : 'https://testwatch.com.au';

export const RedirectUrl = {
  reporting: process.env.REPORTING_APP,
  termOfService: `${baseUrl()}/terms`,
  privatePolicy: `${baseUrl()}/privacy`,
  ppsrDisclaimer: `${baseUrl()}/ppsr-disclaimer`,
  pricing: `${baseUrl()}/pricing`,
  contactUs: `${baseUrl()}/contact-us`
};
