import Cookie from "./cookie"

const XERO_SIGNUP_COOKIE_NAME = 'cw_xero_signup_info'

export type XeroSignupPayload = {
  email: string
  firstName: string
  lastName: string
}

/**
 * Only use in Browser side
 * 
 * @returns 
 */
export const parseXeroSignupFromCookie = (): XeroSignupPayload => {
  const xeroSignupCookie = Cookie.get(XERO_SIGNUP_COOKIE_NAME)

    if (!xeroSignupCookie) {
      return {
        email: '',
        firstName: '',
        lastName: ''
      }
    }

    const parsedXeroSignup = JSON.parse(window.atob(xeroSignupCookie))

    const email = parsedXeroSignup?.email || ''
    const firstName = parsedXeroSignup?.firstname || ''
    const lastName = parsedXeroSignup?.lastname || ''

    return {
      email,
      firstName,
      lastName 
    }
}
