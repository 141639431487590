import axios from 'axios';

axios.interceptors.request.use(
  (request) => {
    if (request.url.startsWith(process.env.API_INTERNAL_BASEURL)) {
      request.withCredentials = true;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response)
);
