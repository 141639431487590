import './Alert.scss';

import React from 'react';
import { toast } from 'react-toastify';
import { AlertTopProps, UseAlertArgs } from './Alert.types';
import { default as cls } from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertTop = (props: AlertTopProps) => {
  const {
    content,
    variant = 'success',
    isCloseable = true,
    closeToast,
    toastProps
  } = props;

  return (
    <div
      data-testid="alert-top"
      className={cls('cw-alert', {
        'cw-alert-success': variant === 'success',
        'cw-alert-error': variant === 'error',
        'cw-alert-warning': variant === 'warning',
        'cw-alert-info': variant === 'info'
      })}
    >
      <div className="cw-alert-body">{content}</div>
      {isCloseable && (
        <button
          aria-label="close-toast"
          className="close-icon"
          onClick={() => closeToast()}
        >
          <FontAwesomeIcon icon={faTimes} size="xs" />
        </button>
      )}
    </div>
  );
};

const useAlert = () => {
  const isActive = (toastId: string) => toast.isActive(toastId);

  const open = (args: UseAlertArgs) => {
    const { duration = false, toastId, ...rest } = args;
    const Content = ({ closeToast, toastProps }) => (
      <AlertTop closeToast={closeToast} toastProps={toastProps} {...rest} />
    );
    return toast(Content, {
      autoClose: duration,
      toastId,
      hideProgressBar: true,
      closeButton: false,
      pauseOnFocusLoss: false
    });
  };

  return { open, isActive };
};

export default useAlert;
