import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { SignupInfo } from '@/routes/SignUp';

const RequireSignup = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const state = location.state as SignupInfo;

  const shouldRedirect =
    !state ||
    !state.account_id ||
    !state.first_name ||
    !state.phone ||
    !state.plan ||
    !state.promocode;

  if (shouldRedirect) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default RequireSignup;
