import axios, { AxiosResponse } from 'axios';
import {
  AlertIncidentsInterface,
  AlertScheduledMaintenencesIterface
} from '@/components/AlertbarWrapper';

const getIncidents = (): Promise<AxiosResponse<AlertIncidentsInterface>> =>
  axios.get<AlertIncidentsInterface>(
    `${process.env.STATUS}/api/v2/incidents.json`
  );

const getScheduledMaintenances = (): Promise<
  AxiosResponse<AlertScheduledMaintenencesIterface>
> =>
  axios.get<AlertScheduledMaintenencesIterface>(
    `${process.env.STATUS}/api/v2/scheduled-maintenances.json`
  );

export default { getIncidents, getScheduledMaintenances };
