import { datadogRum } from '@datadog/browser-rum';

import { buildVer, DDRumConfig } from './config';
import { AuthService } from './services';

const getTracingUrls = (url: string) => [
  process.env.API_V2_BASEURL,
  process.env.API_INTERNAL_BASEURL,
  process.env.CW_AUTH_BASE_URL
].every(domain => url.startsWith(domain));

/* istanbul ignore next */
export const initDatadogRum = async () => {
  datadogRum.init({
    applicationId: DDRumConfig.applicationId,
    clientToken: DDRumConfig.clientToken,
    service: DDRumConfig.serviceName,
    version: buildVer,
    site: DDRumConfig.site,
    env: DDRumConfig.rumEnv,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [getTracingUrls],
    traceSampleRate: 100
  });

  await updateAuthUserInfoToRum();

  datadogRum.startSessionReplayRecording();
};

export const getDatadogRum = () => datadogRum;

export const updateAuthUserInfoToRum = async () => {
  const datadogRumInstance = getDatadogRum();

  if (!datadogRumInstance) {
    return;
  }

  let user = null;

  try {
    user = await AuthService.getAuthUser();
  } catch (error) {
    // TODO: handle error if needed
  }

  if (!user?.id) {
    return;
  }

  datadogRum.setUser({
    id: user.id?.toString?.(),
    name: user.userName,
    email: user.email,
    accountId: user.account?.id?.toString?.()
  });
};
