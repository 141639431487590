import axios from 'axios';

export type ConfirmPayload = {
  plan_id: number;
  card_number: string;
  name_on_card: string;
  expired_date_month: string;
  expired_date_year: string;
  security_code: string;
  fraud_cookie?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

export type SavePartialPayload = Omit<CreateAccountPayload, 'password'>;

export type CreateAccountPayload = {
  plan: string;
  promocode: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
};

export type CreateAccountResponse = {
  account_id: number;
  errors: { code: string; message: string; [k: string]: any }[];
  success: boolean;
};

export type CheckoutSuccessResponse = {
  success: boolean;
  token: string;
};

const checkout = (account_id: number, payload: ConfirmPayload) =>
  axios.post<CheckoutSuccessResponse>(
    `${process.env.API_V2_BASEURL}/account-signup/confirm/${account_id}`,
    payload,
    { withCredentials: true }
  );

const plans = () =>
  axios.get(`${process.env.API_V2_BASEURL}/account-signup/plans`);

const resendCode = (account_id: number) =>
  axios.post(
    `${process.env.API_V2_BASEURL}/account-signup/account-resend-verify-code/${account_id}`
  );

const verifyCode = (verify_code: string, account_id: number) =>
  axios.post(
    `${process.env.API_V2_BASEURL}/account-signup/account-check-verify-code/${account_id}`,
    { verify_code }
  );

const updatePhoneNumber = (phone: string) =>
  axios.post(
    `${process.env.API_V2_BASEURL}/account-signup/update-account-phone-number`,
    { phone },
    { withCredentials: true }
  );

const checkPlanValid = (plan: string) =>
  axios.post(`${process.env.API_V2_BASEURL}/account-signup/plan-exist`, {
    plan
  });

const checkPromoCodeValid = (promocode: string) =>
  axios.post(
    `${process.env.API_V2_BASEURL}/account-signup/promotion-code-valid`,
    { promocode }
  );

const savePartial = (payload: SavePartialPayload) =>
  axios.post(`${process.env.API_V2_BASEURL}/account-signup/partial`, payload, {
    withCredentials: true
  });

const createAccount = (payload: CreateAccountPayload) => {
  return axios.post<CreateAccountResponse>(
    `${process.env.API_V2_BASEURL}/account-signup/create-account`,
    payload,
    { withCredentials: true }
  );
};

const loginWithToken = (token: string) => {
  return axios.post(
    `${process.env.API_INTERNAL_BASEURL}/token_login`,
    { token },
    { withCredentials: true }
  );
};

export default {
  loginWithToken,
  checkout,
  plans,
  updatePhoneNumber,
  resendCode,
  verifyCode,
  savePartial,
  createAccount,
  checkPlanValid,
  checkPromoCodeValid
};
