import React, { useEffect, useState } from 'react';
import { Alertbar } from '@cwlib/react-lib/lib/Alertbar';
import {
  AlertIncidentsInterface,
  AlertScheduledMaintenencesIterface,
  StatusInterface
} from './AlertbarWrapper.types';
import AlertService from '@/services/AlertService';
import { concat, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AxiosResponse } from 'axios';

interface Alert {
  variant: "error" | "warning" | "info" | "success";
  message: string;
  link?: string;
  scheduledFor?: Date;
  scheduledUntil?: Date;
}

const AlertbarWrapper = () => {
  const [alert, setAlert] = useState<Alert>();
  useEffect(() => {
    /**
     * timer() is used here for http polling both the incidents and maintenance calls
     * Incidents take precedence to Maintenance updates
     */
    timer(0, 15 * 60 * 1000)
      .pipe(
        concatMap((_) =>
          concat(
            AlertService.getIncidents(),
            AlertService.getScheduledMaintenances()
          )
        )
      )
      .subscribe(
        (
          response: AxiosResponse<
            AlertIncidentsInterface | AlertScheduledMaintenencesIterface
          >
        ) => {
          const referencedTime = new Date().getTime(),
            INCIDENTS = 'incidents',
            MAINTENANCE = 'scheduled_maintenances',
            SCHEDULED = 'scheduled',
            IN_PROGRESS = 'in_progress',
            resAlerts = response.data;

          const applicationAlerts = (
            'incidents' in resAlerts
              ? resAlerts.incidents
              : resAlerts.scheduled_maintenances
          ).filter((alert) =>
            alert.components.some(
              (component) =>
                component.id === process.env.COMPONENT_APPLICATION_PORTAL_ID
            )
          );
          let filteredApplicationAlerts: StatusInterface[] = [];

          if (applicationAlerts && applicationAlerts.length && !alert) {
            if (INCIDENTS in resAlerts) {
              filteredApplicationAlerts = applicationAlerts
                .filter(
                  (incident) =>
                    [
                      process.env.INCIDENT_RESOLVED,
                      process.env.INCIDENT_POSTMORTEM
                    ].indexOf(incident.status) === -1
                )
                .sort(
                  (a, b) =>
                    new Date(a.created_at).getTime() -
                    new Date(b.created_at).getTime()
                );
            } else if (
              MAINTENANCE in resAlerts &&
              !alert &&
              applicationAlerts.some((m) => m.status === IN_PROGRESS)
            ) {
              filteredApplicationAlerts = applicationAlerts.filter(
                (maintenance) => maintenance.status === IN_PROGRESS
              );
            } else if (
              MAINTENANCE in resAlerts &&
              !alert &&
              applicationAlerts.some((m) => m.status === SCHEDULED)
            ) {
              filteredApplicationAlerts = applicationAlerts
                .filter((maintenance) => maintenance.status === SCHEDULED)
                .filter((maintenance) => {
                  const scheduledForTime = new Date(
                      maintenance.scheduled_for
                    ).getTime(),
                    scheduledUntil = new Date(
                      maintenance.scheduled_until
                    ).getTime(),
                    oneHourFromNow = referencedTime + 30 * 60 * 60 * 1000;

                  return (
                    scheduledForTime < oneHourFromNow &&
                    scheduledUntil > referencedTime
                  );
                })
                .sort(
                  (a, b) =>
                    new Date(a.scheduled_for).getTime() -
                    new Date(b.scheduled_for).getTime()
                );
            }

            if (filteredApplicationAlerts.length) {
              if (MAINTENANCE in resAlerts) {
                const scheduledUntil: Date = new Date(
                    filteredApplicationAlerts[0].scheduled_until
                  ),
                  scheduledFor: Date = new Date(
                    filteredApplicationAlerts[0].scheduled_for
                  );

                /**
                 * Countdown timer for Alertbar
                 * Emits after every 1 sec and updates timer for scheduled for and scheduled until time
                 * terminates when scheduled until time is in the past and clears the alert
                 */
                if (
                  filteredApplicationAlerts.some(
                    (e) => e.status === IN_PROGRESS
                  )
                ) {
                  let message = filteredApplicationAlerts[0].name;
                  if (
                    filteredApplicationAlerts[0] &&
                    filteredApplicationAlerts[0].incident_updates[0] &&
                    filteredApplicationAlerts[0].incident_updates[0].body
                  ) {
                    message =
                      filteredApplicationAlerts[0].incident_updates[0].body;
                  }
                  setAlert({
                    ...alert,
                    message,
                    variant: 'info'
                  });
                } else {
                  setAlert({
                    ...alert,
                    scheduledFor,
                    scheduledUntil
                  });
                }
              } else {
                setAlert({
                  ...alert,
                  message: `${filteredApplicationAlerts[0].name}`,
                  variant: 'error'
                });
              }
            }
          }
        }
      );
  }, []);
  return (
    <>
      {alert && alert.message && alert.variant ? (
        <Alertbar {...alert} dismissible={true} />
      ) : null}
    </>
  );
};

export default AlertbarWrapper;
