import './App.scss';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import RequirePricing from '@/components/RequirePricing';
import RequireSignup from '@/components/RequireSignup';
import Login from '@/routes/Login';

// Lazy load routes
const SignUp = React.lazy(() => import('./routes/SignUp'));
const SmsVerification = React.lazy(() => import('./routes/SmsVerification'));
const Checkout = React.lazy(() => import('./routes/Checkout'));

const App = () => {
  return (
    <div className="App">
      <ToastContainer position="top-center" />
      <React.Suspense fallback={<span>Loading...</span>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/signup/checkout"
            element={
              <RequireSignup>
                <Checkout />
              </RequireSignup>
            }
          />
          <Route
            path="/signup/verification"
            element={
              <RequireSignup>
                <SmsVerification />
              </RequireSignup>
            }
          />
          <Route
            path="/signup"
            element={
              <RequirePricing>
                <SignUp />
              </RequirePricing>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default App;
