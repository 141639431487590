import { AppConfig } from '@/config';
import Cookies from 'js-cookie';

const Cookie = {
  get: (name: string) => Cookies.get(name),
  set: async (name: string, value: string) => {
    await Cookies.set(name, value, {
      ...(!AppConfig.isDebug && { domain: AppConfig.cookieDomain })
    });
  },
  remove: async (name: string) => await Cookies.remove(name, { path: '/', domain: process.env.COOKIE_DOMAIN})
};
export default Cookie;
