import axios, { AxiosResponse } from 'axios';

import { AuthConfig } from '@/config';
import Cookie from '@/utils/cookie';

type AuthUser = {
  id: number;
  userName: string;
  email: string;
  account: {
    id: number;
  };
};

const baseURL = String(process.env.API_V2_BASEURL);
const getAxiosInstance = (token: string = null) =>
  axios.create({
    baseURL,
    headers: {
      ['Accept']: 'application/json',
      ...(token ? { ['Authorization']: `Bearer ${token}` } : {})
    }
  });
const getAuthUser = async () => {
  const token = Cookie.get(AuthConfig.jwtCookieName);

  if (!token) {
    return Promise.resolve(null);
  }

  const axiosInstance = getAxiosInstance(token);

  return axiosInstance
    .get('/user/details')
    .then((res: AxiosResponse<{ user: AuthUser }>) => res?.data?.user ?? null);
};

export default {
  getAuthUser
};
