const applicationId = String(process.env.REACT_APP_DD_RUM_APPLICATION_ID) ?? '';
const clientToken = String(process.env.REACT_APP_DD_RUM_CLIENT_TOKEN) ?? '';

export const DDRumConfig = {
  applicationId,
  clientToken,
  serviceName: String(process.env.REACT_APP_DD_RUM_SERVICE_NAME) ?? '',
  site: String(process.env.REACT_APP_DD_RUM_SITE) ?? '',
  rumEnv: 'prod',
  isEnabled: !!applicationId && !!clientToken,
};
