import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { RedirectUrl } from '@/config';
import ExternalRedirect from '@/components/ExternalRedirect';

const RequirePricing = ({ children }: { children: JSX.Element }) => {
  const [params] = useSearchParams();

  const shouldRedirect = !params.get('plan') || !params.get('promo');

  if (shouldRedirect) {
    return <ExternalRedirect url={RedirectUrl.pricing} />;
  }
  return children;
};

export default RequirePricing;
