import React, { forwardRef, useState } from 'react';
import ReactPinField from 'react-pin-field';
import './OtpInput.scss';
import { OtpInputProps } from './OtpInput.types';
import { default as cls } from 'classnames';

const OtpInput = forwardRef<HTMLInputElement[] | null, OtpInputProps>(
  (props, ref) => {
    const {
      isInvalid = false,
      invalidMsg = 'Code you have entered isn’t valid',
      onChange,
      onComplete
    } = props;
    const numFields = 6;
    const [isCompleted, setIsCompleted] = useState(false);
    const handleCompleted = (code: string) => {
      setIsCompleted(true);
      onComplete && onComplete(code);
    };
    const handleChange = (code: string) => {
      setIsCompleted(code.length === numFields);
      onChange && onChange(code);
    };

    return (
      <div
        data-testid="otp-input"
        className={cls('otp-input', {
          'otp-input-complete': isCompleted,
          'otp-input-invalid': isInvalid
        })}
      >
        <div className="inputs-wrapper">
          <ReactPinField
            ref={ref}
            length={numFields}
            validate={/^[0-9]$/}
            onChange={handleChange}
            onComplete={handleCompleted}
            className="otp-field"
          />
        </div>
        {isInvalid && <div className="invalid-msg">{invalidMsg}</div>}
      </div>
    );
  }
);

export default OtpInput;
