import './LoginVerification.scss';

import React, { useEffect, useRef, useState } from 'react';
import OtpInput from '@/components/OtpInput';
import { Button } from '@cwlib/react-lib/lib/Button';
import { LoginVerificationProps, MfaType } from './LoginVerification.types';
import { useAlert } from '@/components/Alert';
import { LoginService } from '@/services';
import { REACHED_MAX_ATTEMPTS, TOO_MANY_REQUESTS_DEFAULT_MESSAGE } from '@/utils/constants';

const LoginVerification = (props: LoginVerificationProps) => {
  const { mfaType, credentials, title, onSuccess = null } = props;
  const [formStatus, setFormStatus] = useState<{
    isFormInvalid: boolean;
    isValidOtp: boolean;
    isResending: boolean;
    isVerifying: boolean;
    maxAttemptsReached: boolean;
    errorMessage?: string;
  }>({ isFormInvalid: false, isValidOtp: false, isResending: false, isVerifying: false, maxAttemptsReached: false });
  const [otp, setOtp] = useState('');
  const otpRef = useRef<HTMLInputElement[] | null>(null);
  const alert = useAlert();
  const resendCodeToast = useRef(null);

  const resetOtpAndFocus = () => {
    if (otpRef && otpRef.current) {
      otpRef.current.forEach((input) => (input.value = ''));
      otpRef.current[0].focus();
    }
    setOtp('');
  };

  const handleOtpComplete = (otpValue: string) => {
    setFormStatus({
      ...formStatus,
      isFormInvalid: false
    });
    setOtp(otpValue);
  };

  const handleOtpChange = (otpValue: string) => setOtp(otpValue);

  useEffect(() => setFormStatus({
    ...formStatus,
    isValidOtp: otp.length === 6,
    isVerifying: false,
    isResending: false
  }), [otp]);

  const getResendCodeMsg = () => {
    let msg = 'A new verification code has been sent to your';
    if (mfaType === MfaType.SMS) {
      msg += ' mobile number';
      return msg;
    }
    if (mfaType === MfaType.EMAIL) {
      msg += ' email address';
      return msg;
    }
  };

  const isAuthenticator = () => mfaType === MfaType.AUTHENTICATOR;

  const handleResendCode = async () => {
    if (!formStatus.isResending) {
      try {
        setFormStatus({...formStatus, isResending: true});
        await LoginService.login(credentials.email, credentials.password);
        // Prevent duplicate alert
        if (!alert.isActive(resendCodeToast.current)) {
          resendCodeToast.current = alert.open({
            content: getResendCodeMsg(),
            duration: 5000
          });
        }
        resetOtpAndFocus();
        setFormStatus({...formStatus, isFormInvalid: false});
      } catch (err) {
        let errorMessage = err?.message || TOO_MANY_REQUESTS_DEFAULT_MESSAGE;
        errorMessage && alert.open({ variant: 'error', content: errorMessage, duration: 5000 })
      } finally {
        setFormStatus({...formStatus, isResending: false, maxAttemptsReached: false})
      }
    }
  };

  const handleVerify = async () => {
    if (!formStatus.isVerifying) {
      let errorPayload = {};
      try {
        setFormStatus({
          ...formStatus,
          isVerifying: true
        });
        const { data: {detail: { error = undefined, context = '', statusMessage = '' } = {}, redirect} } = await LoginService.login(
          credentials.email,
          credentials.password,
          otp
        );
        if (redirect) {
          onSuccess && onSuccess(redirect);
        } else if (error) {
          errorPayload = {
            ...formStatus,
            isFormInvalid: true,
            errorMessage: statusMessage,
            ...((context.indexOf(REACHED_MAX_ATTEMPTS) > -1) && {maxAttemptsReached: true})
          };
        }
      } catch (err) {
        let errorMessage = err?.message || TOO_MANY_REQUESTS_DEFAULT_MESSAGE;
        errorMessage && alert.open({ variant: 'error', content: errorMessage, duration: 5000 })
      } finally {
        setFormStatus({...formStatus, ...errorPayload, isVerifying: false})
      }
    }
  };

  return (
    <div className="login-verification">
      <div className="heading">Security Verification</div>
      <div className="sub-heading">
        Protecting your account is our top priority
      </div>
      <div className="cw-card">
        <div className="cw-card-title">Verification Code</div>
        {title && <div className="cw-card-subtitle">{title}</div>}
        <OtpInput
          onComplete={handleOtpComplete}
          onChange={handleOtpChange}
          isInvalid={formStatus.isFormInvalid}
          ref={otpRef}
          {...((formStatus.errorMessage?.length > 0) && {invalidMsg: formStatus.errorMessage})}
        />
        <Button
          title={!formStatus.isVerifying ? 'Verify' : 'Processing'}
          type="button"
          active={formStatus.isValidOtp && !formStatus.isFormInvalid && !formStatus.isVerifying && !formStatus.maxAttemptsReached}
          loading={formStatus.isVerifying}
          onClick={handleVerify}          
        />
        <div className="cw-card-footer">
          It may take a minute to receive your code.&nbsp;
          {!isAuthenticator() && (
            <a className="link" onClick={handleResendCode}>
              Resend new code
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginVerification;
