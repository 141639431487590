import { useState, FormEvent } from 'react';

import './LoginForm.scss';
import { DDRumConfig, RedirectUrl } from '@/config';
import { Link } from '@cwlib/react-lib/lib/Link';
import { LinkPrompt } from '@cwlib/react-lib/lib/LinkPrompt';
import { TextInput } from '@cwlib/react-lib/lib/TextInput';
import { Button } from '@cwlib/react-lib/lib/Button';
import { default as Form } from 'react-bootstrap/Form';
import { default as Alert } from 'react-bootstrap/Alert';
import { LoginService } from '@/services';
import { Credentials, MfaType } from '@/components/LoginVerification';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import Cookie from '@/utils/cookie';
import { REDIRECT_COOKIE, REDIRECT_PARAM } from '@/routes/Login/Login';
import { useSearchParams } from 'react-router-dom';
import { updateAuthUserInfoToRum } from '@/datadogRum';

type LoginFormProps = {
  onVerification?: (
    mfaType: MfaType,
    msg: string,
    credentials: Credentials
  ) => void;
  onLoginSuccess?: (redirectUrl: string) => void;
  xeroEmail?: string;
};

export const ERROR_PARAM = 'error';
export const SUCCESS_PARAM = 'success';

/*
  Federation Flow: default state for login page; 
  Aim is to determine if the user is federated and run through the external auth or default sign in
*/
const LoginForm = ({ onVerification, onLoginSuccess, xeroEmail }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(xeroEmail ?? '');
  const [password, setPassword] = useState('');
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState(searchParams.get(ERROR_PARAM) || searchParams.get(SUCCESS_PARAM) || "");
  const [isSuccessMessage, setIsSuccessMessage] = useState(!!searchParams.get(SUCCESS_PARAM));
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
  const [federatedFlow, setFederationFlow] = useState<{isFederated: boolean, loginWithPassword?: boolean, ssoHyperlink?: string}>({
    isFederated: true,
    loginWithPassword: false,
    ssoHyperlink: ''
  });

  const toggleShowPasswordResetForm = () => {
    setShowPasswordResetForm(!showPasswordResetForm);
    setMessage('');
    setIsSuccessMessage(false);
    setPassword('');
  };

  const onEditEmailAction = () => {
    // User can return from federated, non-federated or reset password flow
    // Clear password, error messages, sso link, and enable federated flow while keeping the email as is
    setPassword('');
    setShowPasswordResetForm(false);
    setMessage('');
    setFederationFlow({
      isFederated: true,
      loginWithPassword: false,
      ssoHyperlink: ''
    });
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onLoginFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email.length || (!password.length && !showPasswordResetForm)) {
      return;
    }
    setMessage('');
    setIsSuccessMessage(false);
    showPasswordResetForm ? resetPasswordSubmit() : signInFormSubmit();
  };

  const signInFormSubmit = () => {
    setLoading(true);
    LoginService.login(email, password)
      .then(async ({ data }) => {
        if (DDRumConfig.isEnabled) {
          await updateAuthUserInfoToRum();
        }

        if (data.redirect) {
          onLoginSuccess(data.redirect);
          return;
        } else if (data.detail) {
          const {deliveryMethod, statusMessage, status} = data.detail;
          if (status === 'ACTIVE') {
            onVerification(deliveryMethod, statusMessage, { email, password });
            return;
          }
          if (statusMessage) {
            setMessage(statusMessage);
            return;
          }
        }        
      })
      .catch((err) => {
        if (err?.data?.message) {
          setMessage(err.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetPasswordSubmit = () => {
    LoginService.forgotPassword(email)
      .then((res) => {
        if (res && res.data && res.data.error) {
          setMessage(res.data.error || "There was an error, please try again.");
          return;
        }
        if (res && res.data && res.data.message) {
          setIsSuccessMessage(true);
          setMessage(res.data.message);
        } else {
          setMessage('There was an error with your request, please try again or contact our customer support team.');
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          setMessage(err.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkIfUserIsFederated = (email: string) => {
    LoginService.checkIfUserIsFederated(email).then(({
      data: {
        data: {
          is_federated: isFederated, 
          federation_link = null
        }
      }}) => {
      if (isFederated) {
        !federatedFlow.isFederated && setFederationFlow({isFederated: true, loginWithPassword: false});
        setFederationFlow({...federatedFlow, ssoHyperlink: federation_link});
      } else setFederationFlow({isFederated: false});
    }).catch(err => {
      setFederationFlow({isFederated: false});
    }).finally(() => {
      setMessage('');
    });
  }

  return (
    <>
      <Form className="cw-login-form" onSubmit={async (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          if (federatedFlow.isFederated && !federatedFlow.loginWithPassword) {
            if (federatedFlow.ssoHyperlink) {
              const redirectCookie = Cookie.get(REDIRECT_COOKIE);
              if (!redirectCookie) {
                const redirectUrl = searchParams.get(REDIRECT_PARAM);
                redirectUrl?.length > 0 && Cookie.set(REDIRECT_COOKIE, redirectUrl);
              }
              window.location.replace(federatedFlow.ssoHyperlink);
            } else {
              if (email.length > 0) checkIfUserIsFederated(email);
              else setMessage('please enter email address');
            }
          } else onLoginFormSubmit(e);          
        }} noValidate>
        {message.length ? (
          <Alert
            className="cw-login-form-alert"
            variant={isSuccessMessage ? 'success' : 'danger'}
          >
            {message}
          </Alert>
        ) : null}
        <Form.Group className="cw-login-form-group cw-login-form-group-title">
          <h1>{showPasswordResetForm ? 'Forgot Password' : 'Login'}</h1>
          <LinkPrompt
            style="info"
            linkRef={RedirectUrl.pricing}
            promptMessage="Need a CreditorWatch account?"
            linkTitle="Sign up"
          />
        </Form.Group>
        <Form.Group className="cw-login-form-group cw-login-form-input-group">
          <TextInput
            inputType="email"
            autoComplete="off"
            placeholder="Email Address"
            value={email}
            onChange={onEmailChange}            
            disabled={(federatedFlow.isFederated && federatedFlow.ssoHyperlink.length > 0) || !federatedFlow.isFederated} //only editable when user is on scenario1
            {...((email?.length > 0 && ((federatedFlow.isFederated && federatedFlow.ssoHyperlink.length > 0) || !federatedFlow.isFederated)) && {iconConfig: {
              right: {
                icon: faPencil,
                onIconClickAction: onEditEmailAction
              }
            }})}
            {...(message.length && { state: 'error' })}
          />
          {!showPasswordResetForm && (!federatedFlow.isFederated || federatedFlow.loginWithPassword) && (
            <TextInput
              inputType="password"
              placeholder="Password"
              autoComplete="off"
              value={password}
              onChange={onPasswordChange}
              {...(message.length && { state: 'error' })}
            />
          )}
        </Form.Group>

        <Form.Group className="cw-login-form-group signin-links">
          <div className='cw-login-form-btns-group'>
            <Button title={(() => {
                if(showPasswordResetForm) return 'Reset Password';
                else if (federatedFlow.isFederated && !federatedFlow.ssoHyperlink) return 'Next';
                else if (federatedFlow.isFederated && federatedFlow.ssoHyperlink && !federatedFlow.loginWithPassword) return 'SSO';
                else return 'Sign In';
              })()}
              type="submit"
              loading={loading}
              isCTA={true}              
            />            
          </div>
          {(!federatedFlow.isFederated || federatedFlow.loginWithPassword) && <Link
            title={showPasswordResetForm ? 'Sign in' : 'Forgot Password?'}
            onClick={toggleShowPasswordResetForm}
          />}
          {federatedFlow.isFederated && federatedFlow.ssoHyperlink && !federatedFlow.loginWithPassword && <>
              <span className='divider'>
                <span className='divider-line'></span>
                <span className='divider-text'>or</span>
                <span className='divider-line'></span>
              </span>
              <Button title="Continue with password" 
                variant='secondary'
                onClick={() => setFederationFlow({...federatedFlow, loginWithPassword: true})} 
                isCTA={true} />
            </>}
        </Form.Group>
      </Form>
    </>
  );
};

export default LoginForm;
