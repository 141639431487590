import React, { useState, useEffect, useRef } from 'react';
import AlertbarWrapper from '@/components/AlertbarWrapper';
import LoginForm from '@/components/LoginForm';
import logo from '@/assets/img/logo.svg';
import { Image } from '@cwlib/react-lib/lib/Image';
import { Carousel } from '@cwlib/react-lib/lib/Carousel';
import CarouselSlide1 from '@/assets/img/login-carousel-1.png';
import CarouselSlide2 from '@/assets/img/login-carousel-2.png';
import './Login.scss';
import LoginVerification, {
  Credentials,
  MfaType
} from '@/components/LoginVerification';
import useDocumentTitle from '@/hooks';
import { useSearchParams } from 'react-router-dom';
import { useAlert } from '@/components/Alert';
import Cookie from '@/utils/cookie';
import { parseXeroSignupFromCookie } from '@/utils/xeroSignup';

const carouselConfig = [
  {
    heading: 'Collections',
    titlePrimary: 'GET PAID FASTER, ',
    titleHero: 'Easier',
    description:
      'Automate your accounts receivable and get time back to focus on work that really needs your attention.',
    learnMoreLinkRef: 'https://creditorwatch.com.au/product/management/creditorwatch-collect/?utm_source=web&utm_medium=login',
    imageLink: CarouselSlide1
  },
  {
    heading: 'our product range',
    titlePrimary: 'automated customer',
    titleHero: 'onboarding',
    description:
      'We take the time and effort out of customer onboarding with our automated ApplyEasy product.',
    learnMoreLinkRef: 'https://applyeasy.com.au/',
    imageLink: CarouselSlide2
  }
];
export const REDIRECT_PARAM = 'redirectUrl';
export const REDIRECT_COOKIE = 'redirect_to';

const Login = () => {
  useDocumentTitle('Login | CreditorWatch');  
  const [showMFA, setShowMFA] = useState<boolean>(false);
  const [verifyMessage, setVerifyMessage] = useState<string>('');
  const [mfaType, setMfaType] = useState<MfaType>(null);
  const [credentials, setCredentials] = useState<Credentials>(null);
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const xeroSignUpMsgRef = useRef(null);
  const xeroSignupCookie = parseXeroSignupFromCookie();

  useEffect(() => {
    handleUserSignUpXero()
  },[]);

  const handleUserSignUpXero = () => {
    if (isFromXeroSignUp()) {
      showExistingAccountMsg();
    }
  }

  const setEmailField = () => {
      return isFromXeroSignUp() ? xeroSignupCookie.email : '';
  }

  const isFromXeroSignUp = () => {
    const redirectUrl = searchParams.get('redirectUrl');
    const onlyFromXeroRegex = new RegExp('.\/xero$');
    return xeroSignupCookie.email.length && onlyFromXeroRegex.test(redirectUrl);
  }

  const showExistingAccountMsg = () => {
    if (!alert.isActive(xeroSignUpMsgRef.current)) {
      xeroSignUpMsgRef.current = alert.open({
        variant: 'info',
        content: 'It looks like you already have a CreditorWatch account. You can log in to your account to continue with your Xero setup or select the forgot password option if you need to.',
        duration: 5000
      });
    }
  }

  const handleVerification = (mfaType, msg, credentials) => {
    setShowMFA(true);
    setVerifyMessage(msg);
    setMfaType(mfaType);
    setCredentials(credentials);
  };

  const redirect = async (defaultRedirectUrl: string) => {
    const redirectCookie = Cookie.get(REDIRECT_COOKIE);
    const redirectParam = searchParams.get(REDIRECT_PARAM);
    const onlyCreditorWatchDomainsRegex = new RegExp('^(https:\\/\\/)?.*(creditorwatch|testwatch)\\.com\\.au(\\/.*)?$');
    let redirectUrl = defaultRedirectUrl;
    
    Cookie.remove(REDIRECT_COOKIE);
    if (onlyCreditorWatchDomainsRegex.test(redirectCookie)) {
      redirectUrl = redirectCookie;
    } else if (onlyCreditorWatchDomainsRegex.test(redirectParam)) {
      redirectUrl = redirectParam;
    }
    window.location.assign(redirectUrl)
  };

  return (
    <>
      <div className="row login-wrapper">
        <AlertbarWrapper />
        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 login-lhs">
          <div className="logo-wrapper">
            <Image src={logo} alt="logo" width="14rem" />
          </div>
          <div className="loginform-wrapper">
            <LoginForm
              onVerification={handleVerification}
              onLoginSuccess={redirect}
              xeroEmail={setEmailField()}
            />
          </div>
        </div>
        <div className="col-lg-7 col-md-6 d-none d-md-block login-rhs">
          <Carousel interval={4000} data={carouselConfig} />
        </div>
      </div>
      {showMFA && (
        <LoginVerification
          mfaType={mfaType}
          title={verifyMessage}
          credentials={credentials}
          onSuccess={redirect}
        />
      )}
    </>
  );
};

Login.propTypes = {};

export default Login;
