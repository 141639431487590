export enum MfaType {
  SMS = 'SMS',
  AUTHENTICATOR = 'AUTHENTICATOR',
  EMAIL = 'EMAIL'
}

export type Credentials = { email: string; password: string };

export type LoginVerificationProps = {
  title: string;
  mfaType: MfaType;
  credentials: Credentials;
  onSuccess?: (redirectUrl: string) => void;
};

export interface ResendCodeSuccessResponse {
  mfa: string;
  detail: {
    status: string;
    statusMessage: string;
    deliveryMethod: MfaType;
    context: string;
  };
}
